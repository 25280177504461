import { PageType } from "./pageType";
import * as Constants from './constants';

const amazonAffiliateLink = process.env.NEXT_PUBLIC_AMAZON_AFFILIATE_LINK;
  const shopDomain = process.env.NEXT_PUBLIC_API_URL;

  export let mkdbConfig: PageType = {
    id: "mkbhd",
    logoUrl: '/mkbhd_profile.jpeg',
    logoHeight: 80,
    logoWidth: 80,
    buttonColor: '#3358d4',
    inputPlaceholder: Constants.ConstPlaceHolder,
    helpfulLabel: 'Helpful?',
    shopUrl: shopDomain + '/shopWithX',
    storeId: 'mkbhd',
    chatUrl: shopDomain + '/chat',
    recsUrl: shopDomain + '/recPerson',
    optionsUrl: shopDomain + '/options',
    affiliateLink: Constants.affiliateLink,
    productHeader: 'Products',
    productPlaceholder: 'Best flatscreen tv under $2000',
    recentProducts: [
      "best smartphones for photography",
      "top smartwatches for fitness tracking",
      "leading wireless earbuds for sound quality",
      "best laptops for video editing",
      "top tablets for graphic design",
      "leading cameras in smartphones",
      "best headphones for music",
      "top fitness trackers for everyday use",
      "best AI wearables",
    ]
    ,
    productButtonLabel: 'Set Product',
    feedbackPopupChatLength: 5,
    productCardHeader: "",
    productInstruction: 'What tech products do you need help buying?',
    subheader: 'An AI expert to help you decide what to buy',
    showMoreProductsText: 'More Items',
    externalLinkLabel: 'See on Amazon',
    website: '/landingpage',
    linkSiteSubHeader: false,
    isMainPage: true,
    language: 'English',
    botName: ''
    //'productInstruction': 'Please select a product from the list below or type your own answer in the input field.',
};




