import { PageType } from "./pageType";
import * as Constants from './constants';

const amazonAffiliateLink = process.env.NEXT_PUBLIC_AMAZON_AFFILIATE_LINK;
  const shopDomain = process.env.NEXT_PUBLIC_API_URL;

export let BeautyConfig: PageType = {
            id: "supplements",
            logoUrl: '/logo-circle.png',
            logoHeight: 80,
            logoWidth: 80,
            buttonColor: '#0077b6',
            inputPlaceholder: Constants.ConstPlaceHolder,
            shopUrl: shopDomain + '/shopEverythingTest',
            storeId: 'beauty',
            chatUrl: shopDomain + '/chat',
            recsUrl: shopDomain + '/recTest',
            optionsUrl: shopDomain + '/options',
            productPlaceholder: 'moisturizer for acne-prone skin',
            helpfulLabel: 'Helpful?',
            productHeader: 'Products',
            feedbackPopupChatLength: 500,
            isMainPage: true,
            affiliateLink: Constants.affiliateLink,
            recentProducts: [
              "a broad spectrum sunscreen for daily protection",
              "a brightening vitamin C serum for bright skin",
              "an anti-aging foundation with hyaluronic acid",
              "nourishing lip balm with SPF",
              "a volumizing mascara for dramatic lashes",
              "a lightweight setting powder for oily skin",
              "hydrating overnight sleeping mask",
              "gentle exfoliating scrub for glowing skin",
              "a long-lasting liquid lipstick in bold colors",
              "soothing under-eye concealer for dark circles",
              "shimmering highlighter for a dewy finish"
            ],
            productButtonLabel: 'Set Product',
            productInstruction: 'What beauty products do you want to buy?',
            productCardHeader: '', //product recommendatons in spanish
            showMoreProductsText: 'More Books',
            website: "",
            subheader: 'Book recommendations',
            linkSiteSubHeader: false,
            externalLinkLabel: 'See on Amazon',
            language: 'English',
            botName: 'Page Pursuer'
};
