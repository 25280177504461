import { PageType } from "./pageType";
import * as Constants from './constants';

const amazonAffiliateLink = process.env.NEXT_PUBLIC_AMAZON_AFFILIATE_LINK;
  const shopDomain = process.env.NEXT_PUBLIC_API_URL;

  export let healthConfig: PageType = {
    id: "supplements",
    logoUrl: '/default-black.png',
    logoHeight: 80,
    logoWidth: 80,
    buttonColor: '#3358d4',
    inputPlaceholder: Constants.ConstPlaceHolder,
    helpfulLabel: 'Helpful?',
    shopUrl: shopDomain + '/shopWithX',
    // 'shopUrl': '/api/shop',
    storeId: 'health',
    chatUrl: shopDomain + '/chat',
    recsUrl: shopDomain + '/recPerson',
    optionsUrl: shopDomain + '/options',
    affiliateLink: Constants.affiliateLink,
    productHeader: 'Products',
    productPlaceholder: 'best caffeine-free organic pre workout for men',
    recentProducts: [
      "best nootropics for cognitive enhancement",
      "top supplements for brain health",
      "Omega-3 supplements for brain health",
      "Neurofeedback systems for home use",
      "wearable stress reducer",
      "Blue light blocking glasses for better sleep",
      "leading sleep aids reviewed by neuroscientists",
      "biohacking devices for performance",
      "supplements recommended by Andrew Huberman",
      "headband for meditation feedback",
      "circadian rhythm sleep lamps",
      "high-quality L-theanine supplements",
      "supplement stacks for focus",
      "advanced GABA supplements for anxiety relief"
    ]
    ,
    productButtonLabel: 'Set Product',
    feedbackPopupChatLength: 5,
    productCardHeader: "",
    productInstruction: 'What do you want to buy?',
    subheader: 'An AI expert to help you decide what to buy',
    showMoreProductsText: 'More Items',
    externalLinkLabel: 'See on Amazon',
    website: '/landingpage',
    linkSiteSubHeader: false,
    isMainPage: true,
    language: 'English',
    botName: '',
    sources: [  "@hubermanlab", "@BryanJohnson"]
    //'productInstruction': 'Please select a product from the list below or type your own answer in the input field.',
};




