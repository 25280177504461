import { PageType } from "./pageType";
import * as Constants from './constants';

const amazonAffiliateLink = process.env.NEXT_PUBLIC_AMAZON_AFFILIATE_LINK;
  const shopDomain = process.env.NEXT_PUBLIC_API_URL;

  export let supplementsConfig: PageType = {
    id: "supplements",
    logoUrl: '/default-black.png',
    logoHeight: 80,
    logoWidth: 80,
    buttonColor: '#3358d4',
    inputPlaceholder: Constants.ConstPlaceHolder,
    helpfulLabel: 'Helpful?',
    shopUrl: shopDomain + '/shopEverythingTest',
    // 'shopUrl': '/api/shop',
    storeId: 'supplements',
    chatUrl: shopDomain + '/chat',
    //'chatUrl': 'api/chat',
    // 'recsUrl':  '/api/recs',
    // 'optionsUrl':  '/api/options',
    recsUrl: shopDomain + '/recTest',
    optionsUrl: shopDomain + '/options',
    affiliateLink: Constants.affiliateLink,
    productHeader: 'Products',
    productPlaceholder: 'best caffeine-free organic pre workout for men',
    recentProducts: [
      "best supplements for joint health",
      "some vitamins for hair growth and strength",
      "I need natural remedies for better sleep quality",
      "I want protein powder for muscle recovery",
      "looking for supplements for boosting immune system",
      "essential oils for stress relief",
      "looking for herbal teas for digestion",
      "omega-3 fatty acids for heart health",
      "probiotics for gut health",
      "supplements for skin radiance",
      "minerals for bone strength",
      "antioxidants for overall wellness",
      "supplements for energy and vitality",
      "adaptogens for stress management",
      "best supplements for enhancing cognitive function",
      "supplements for weight management",
      "looking for supplements for hormonal balance",
      "supplements for eye health",
      "supplements for liver detoxification",
      "supplements for respiratory health",
      "best supplements for joint flexibility",
      "supplements for mood enhancement",
      "supplements for pre-workout energy",
      "best supplements for post-workout recovery",
      "supplements for men's health",
      "supplements for women's health",
      "supplements for children's nutrition",
      "supplements for seniors' vitality",
      "supplements for overall well-being"
    ]
    ,
    productButtonLabel: 'Set Product',
    feedbackPopupChatLength: 5,
    productCardHeader: "",
    productInstruction: 'What supplements do you want to buy?',
    subheader: 'An AI expert to help you decide what to buy',
    showMoreProductsText: 'More Items',
    externalLinkLabel: 'See on Amazon',
    website: '/landingpage',
    linkSiteSubHeader: false,
    isMainPage: true,
    language: 'English',
    botName: ''
    //'productInstruction': 'Please select a product from the list below or type your own answer in the input field.',
};




