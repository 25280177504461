import { PageType } from "./pageType";
import * as Constants from './constants';

const amazonAffiliateLink = process.env.NEXT_PUBLIC_AMAZON_AFFILIATE_LINK;
  const shopDomain = process.env.NEXT_PUBLIC_API_URL;

export let SkincareConfig: PageType = {
            id: "supplements",
            logoUrl: '/logo-circle.png',
            logoHeight: 80,
            logoWidth: 80,
            buttonColor: '#0077b6',
            inputPlaceholder: Constants.ConstPlaceHolder,
            shopUrl: shopDomain + '/shopEverythingTest',
            storeId: 'skincare',
            chatUrl: shopDomain + '/chat',
            recsUrl: shopDomain + '/recTest',
            optionsUrl: shopDomain + '/options',
            productPlaceholder: 'moisturizer for acne-prone skin',
            helpfulLabel: 'Helpful?',
            productHeader: 'Products',
            feedbackPopupChatLength: 500,
            isMainPage: true,
            affiliateLink: Constants.affiliateLink,
            recentProducts: [
              "a moisturizer with sun protection for dry skin",
              "a vitamin C serum to make skin glow",
              "a retinol cream to smooth out wrinkles",
              "a hydrating mask for sensitive skin",
              "a tea tree oil face wash for skin with acne",
              "a rose water toner to keep skin pH balanced",
              "a charcoal mask to get rid of blackheads",
              "an aloe vera gel to cool down sunburn",
              "a serum with hyaluronic acid for super moisturizing",
              "a green tea eye cream to lessen eye bags",
              "a witch hazel cleanser for skin that gets oily"
            ],
            productButtonLabel: 'Set Product',
            productInstruction: 'What do you want to buy?',
            productCardHeader: '', //product recommendatons in spanish
            showMoreProductsText: 'More Books',
            website: "",
            subheader: 'Book recommendations',
            linkSiteSubHeader: false,
            externalLinkLabel: 'See on Amazon',
            language: 'English',
            botName: 'Page Pursuer'
};
