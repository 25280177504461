import { PageType } from "./pageType";
import * as Constants from './constants';

const amazonAffiliateLink = process.env.NEXT_PUBLIC_AMAZON_AFFILIATE_LINK;
  const shopDomain = process.env.NEXT_PUBLIC_API_URL;

export let TechConfig: PageType = {
            id: "tech",
            logoUrl: '/tech.jpeg',
            logoHeight: 80,
            logoWidth: 80,
            buttonColor: '#0077b6',
            inputPlaceholder: Constants.ConstPlaceHolder,
            shopUrl: shopDomain + '/shopWithX',
            storeId: 'tech',
            chatUrl: shopDomain + '/chat',
            recsUrl: shopDomain + '/recPerson',
            optionsUrl: shopDomain + '/options',
            productPlaceholder: 'best computer for gaming with a budget of 1000 dollars',
            helpfulLabel: 'Helpful?',
            productHeader: 'Products',
            feedbackPopupChatLength: 500,
            isMainPage: true,
            affiliateLink: Constants.affiliateLink,
            recentProducts: [
              "a gaming laptop with a high refresh rate screen and RTX 3070",
              "a wireless mouse with customizable buttons for graphic design",
              "a mechanical keyboard with silent switches for office use",
              "a 4K monitor with color accuracy for video editing",
              "a noise-cancelling headphones for studying in a noisy environment",
              "a portable SSD with USB-C for fast file transfers",
              "a webcam with 1080p resolution and built-in microphone for streaming",
              "a smart speaker with high-quality sound and voice assistant compatibility",
              "a fitness tracker with heart rate monitor and sleep tracking",
              "a smartphone with a long battery life and high-resolution camera",
              "a tablet with a stylus for digital art"
            ],
            productButtonLabel: 'Set Product',
            productInstruction: 'What tech are you looking for?',
            productCardHeader: '', //product recommendatons in spanish
            showMoreProductsText: 'More Books',
            website: "",
            subheader: 'Book recommendations',
            linkSiteSubHeader: false,
            externalLinkLabel: 'See on Amazon',
            language: 'English',
            botName: 'Page Pursuer',
            sources:  [
              "@mkbhd",
              "@LinusTechTips",
              "@unboxtherapy",
              "@austinevans",
              "@Dave2D",
              "@TLD",
              "@TheMrMobile",
              "@mondobytes",
              "@iJustine",
              "@UrAvgConsumer",
              "@JerryRigEverything"
              ]
};
