import { BookConfig } from "./books";
import { genaiConfig } from "./genai";
import { giftemConfig } from "./giftem";
import { mainConfig } from "./main";
import { momConfig } from "./mom";
import { dadConfig } from "./dad";
import { producthuntConfig } from "./producthunt";
import { stonetoolingConfig } from "./stonetooling";
import { weedConfig } from "./weed";
import { shopifyDemoConfig } from "./shopifydemo";
import { oxfordConfig } from "./oxford";
import { minAppConfig } from "./minapp"
import { SkincareConfig } from "./skincare"
import { TechConfig } from "./tech"
import { OutdoorConfig } from "./outdoor"
import { supplementsConfig } from "./supplements"
import { BeautyConfig } from "./beauty"
import { mkdbConfig } from "./mkbhd"
import { leahConfig } from "./leahhalton";
import { boycottisraelConfig } from "./boycottisrael";
import { healthConfig } from "./health";
import { chemicalfreeConfig } from "./chemicalfree";
import { youtubeConfig } from "./youtube";

//NOTE THIS IS SO WE CAN RENDER CHATS AFTER
//DO NOT DELETE TAGS OR PAST ONES WONT RENDER
//THERE IS STILL THE URL "/mom" THAT ALSO MATTERS
const StoreIdConfigMap = {
    "products_oxy": mainConfig,
    "producthunt": producthuntConfig,
    "oxford": oxfordConfig,
    "giftem": giftemConfig,
    "stonetool-sbert-07-06-2023": stonetoolingConfig,
    "genai": genaiConfig,
    "weed": oxfordConfig,
    "mom": momConfig,
    "dad": dadConfig,
    "books": BookConfig,
    "books_oxy_shitty": BookConfig,
    "quickstart-cd2c5473.myshopify.com": shopifyDemoConfig,
    "oxford": oxfordConfig,
    "": mainConfig,
    "skincare": SkincareConfig,
    "beauty": BeautyConfig,
    "tech": TechConfig,
    "outdoor": OutdoorConfig,
    "minapp": minAppConfig,
    "supplements": supplementsConfig,
    "mkbhd": mkdbConfig,
    "leahhalton": leahConfig,
    "boycottisrael": boycottisraelConfig,
    "health": healthConfig,
    "chemicalfree": chemicalfreeConfig,
    "youtube": youtubeConfig
}

export function getConfig(storeId) {
    let config = StoreIdConfigMap[storeId];
    if (config) {
        return config;
    }
    return minAppConfig;
}
