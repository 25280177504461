
import {PageType} from './pageType';
import * as Constants from './constants';


const shopDomain = process.env.NEXT_PUBLIC_API_URL;

export let weedConfig: PageType =
        {
            id: "weed",
            logoUrl: '/weed.png',
            logoHeight: 80,
            logoWidth: 80,
            buttonColor: '#009900',
            inputPlaceholder: Constants.ConstPlaceHolder,
            shopUrl: shopDomain + '/shop',
            recsUrl: shopDomain + '/recs',
            optionsUrl: shopDomain + '/options',
            storeId: 'oasis',
            chatUrl: shopDomain + '/chat',
            productHeader: 'Products',
            helpfulLabel: 'Helpful?',
            productPlaceholder: '',
            feedbackPopupChatLength: 500,
            isMainPage: false,
            affiliateLink: '',
            recentProducts: [
                'gummies',
                'thc chocolate',
                'tincture',
            ],
            productButtonLabel: 'Set Product',
            productInstruction: 'Describe what you want to buy to begin:',
            productCardHeader: '',
            showMoreProductsText: 'More Items',
            website: 'https://en.wikipedia.org/wiki/Cannabis_(drug)',
            externalLinkLabel: 'More info',
            subheader: 'Don\'t get in the weeds about your weed. Our AI weed expert is here to help.',
            linkSiteSubHeader: false,
            language: 'English',
            botName: 'Wheedy'
        };




