
import {PageType} from './pageType';
import * as Constants from './constants';


const shopDomain = process.env.NEXT_PUBLIC_API_URL;

export let stonetoolingConfig: PageType =
        {
            id: "stonetooling",
            logoUrl: '/stonetooling-logo.png',
            logoHeight: 40,
            logoWidth: 300,
            buttonColor: '#0062b1',
            inputPlaceholder: Constants.ConstPlaceHolder,
            shopUrl: shopDomain + '/shop',
            recsUrl: shopDomain + '/recs',
            optionsUrl: shopDomain + '/options',
            storeId: 'stonetooling-com',
            chatUrl: shopDomain + '/chat',
            productHeader: 'Products',
            helpfulLabel: 'Helpful?',
            productPlaceholder: 'miter saw',
            feedbackPopupChatLength: 500,
            isMainPage: false,
            affiliateLink: '',
            recentProducts: [
                'cordless drills',
                'tile saw',
                'grout'
            ],
            productButtonLabel: 'Set Product',
            productInstruction: 'Find me a...',
            productCardHeader: '',
            showMoreProductsText: 'More Items',
            website: 'https://stonetooling.com/',
            externalLinkLabel: 'See on Stonetooling.com',
            subheader: 'An AI expert to help you decide what to buy',
            linkSiteSubHeader: false,
            botName: "Stonetooling",
            language: 'English'
        };
