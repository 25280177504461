import { PageType } from "./pageType";
import * as Constants from './constants';

const amazonAffiliateLink = process.env.NEXT_PUBLIC_AMAZON_AFFILIATE_LINK;
  const shopDomain = process.env.NEXT_PUBLIC_API_URL;

  export let chemicalfreeConfig: PageType = {
    id: "chemcialfree",
    logoUrl: '/default-black.png',
    logoHeight: 80,
    logoWidth: 80,
    buttonColor: '#3358d4',
    inputPlaceholder: Constants.ConstPlaceHolder,
    helpfulLabel: 'Helpful?',
    shopUrl: shopDomain + '/shopEverythingTest',
    storeId: 'chemicalfree',
    chatUrl: shopDomain + '/chat',
    recsUrl: shopDomain + '/recTest',
    optionsUrl: shopDomain + '/options',
    affiliateLink: Constants.affiliateLink,
    productHeader: 'Products',
    productPlaceholder: '',
    recentProducts: [
      "best non-stick frying pan for eggs",
      "food storage containers set",
      "long-lasting waterproof mascara for sensitive eyes",
      "durable vinyl flooring options for kitchens",
      "aromatherapy scented candles with essential oils",
      "educational toys for toddlers age 3",
      "quick-dry gel nail polish in red",
      "fabric shower curtains",
      "heavy-duty garden hoses on sale",
      "eco-friendly yoga mats for hot yoga",
      "glass baby bottles with silicone sleeve",
      "food packaging for homemade cookies",
      "interior wall paint in neutral colors",
      "enzyme carpet cleaner for pet stains",
      "lightweight waterproof camping gear for beginners",
      "breathable athletic wear for summer workouts",
      "HEPA air purifier for large rooms",
      "cosmetic products for acne-prone skin",
      "stainless steel kitchen utensils set",
      "microwave-safe Tupperware set",
      "hair styling products for curly hair"
    ]
    ,
    productButtonLabel: 'Set Product',
    feedbackPopupChatLength: 5,
    productCardHeader: "",
    productInstruction: "What do you want to buy? I'll make sure there's no harmful chemicals.",
    subheader: 'An AI expert to help you decide what to buy',
    showMoreProductsText: 'More Items',
    externalLinkLabel: 'See on Amazon',
    website: '/landingpage',
    linkSiteSubHeader: false,
    isMainPage: true,
    language: 'English',
    botName: '',
    searchPromptModifier: "Also, this customer is heavily concerned about avoiding chemicals in their purchase. Add the correct search modifiers like BPA-free, non-toxic, PFAS-free, no VOCs, no phthalates, paraben-free, or PAH-free, depending on the type of product the customer is trying to buy. Include only the chemical search modifiers that are relevant to the product they are trying to buy and omit any that are not pertinent. For clothing, which is high in plastics, aim for products with 100% organic natural fibers",
    searchReminder: "Remember, this customer is averse to harmful chemicals (exaples being: BPA, BPF, BPS, PFAS, PCBs, PBDEs, heavy metals, VOCs, phthalates, parabens, PAHs, ect.). adjust each search query to explicitly indicate the product must be free of the specific chemicals associated with that type of product. Incorporate the format '[specific chemical]-free' into the query based on the product the customer wants. For example, for cookware you might add 'PFOA-free, PFAS-free, lead-free' or for beauty products or 'paraben-free, phthalate-free, formaldehyde-free' or for packaged foods 'BPA-free, phthalates-free, PFAS-free' or for clothing '100% natural fibers' where the fiber depends on the type of clothing being requested."

};




