
import {PageType} from './pageType';
import * as Constants from './constants';

const amazonAffiliateLink = process.env.NEXT_PUBLIC_AMAZON_AFFILIATE_LINK;

const shopDomain = process.env.NEXT_PUBLIC_API_URL;

export let dadConfig: PageType ={
            id: "dad",
            logoUrl: '/logo.png',
            logoHeight: 80,
            logoWidth: 80,
            //color: '#ff344c',
            buttonColor: '#03adfd',
            inputPlaceholder: "",
            shopUrl: shopDomain + '/shopEverythingTest',
            recsUrl: shopDomain + '/recTest',
            optionsUrl: shopDomain + '/options',
            storeId: 'dad',
            chatUrl: shopDomain + '/chat',
            productHeader: 'Products',
            feedbackPopupChatLength: 5,
            isMainPage: true,
            affiliateLink: Constants.affiliateLink,
            productPlaceholder: 'workout bag',
            recentProducts: [
                'gardening gloves',
                'bucket hat',
                'dad beer mug',
                "new balances",
                "don't be a dumb bass t shirt",
                "apple watch",
                "massage gun",
                "grill daddy apron",
                "The grillfather cutting board",
                "BBQ tool set",
                "mug warmers",
                "toolbox",
                "workbench"
            ],
            productButtonLabel: 'Set Gift',
            helpfulLabel: 'Helpful?',
            productInstruction: "Let's find that perfect gift!",
            productCardHeader: '',
            showMoreProductsText: 'More Items',
            subheader: 'An AI to help you find perfect Father\'s day gifts',
            website: 'https://getproduct.help/dad',
            'linkSiteSubHeader': false,
            externalLinkLabel: 'See on Amazon',
            language: 'English',
            botName: 'Dadbot',
            searchPromptModifier: "This customer is trying to buy a gift for their dad for father's day. Help customers find thoughtful gifts for their recipients and personalize the gift-finding process to find the most fitting gifts based on what you're told.",
            searchReminder: "Remember, this customer is trying to buy a gift for theri dad for father's day. Help customers find thoughtful and unqiue gifts for their recipients and personalize the gift-finding process to find the most fitting gifts based on what you're told."
        };