
import {PageType} from './pageType';
import * as Constants from './constants';


const shopDomain = process.env.NEXT_PUBLIC_API_URL;

export let shopifyDemoConfig: PageType =
        {
            id: "quickstart-cd2c5473.myshopify.com",

            logoUrl: '/oxford-logo.png',
            logoHeight: 40,
            logoWidth: 300,
            buttonColor: '#0062b1',
            inputPlaceholder: Constants.ConstPlaceHolder,
            shopUrl: shopDomain + '/shop',
            recsUrl: shopDomain + '/recs',
            optionsUrl: shopDomain + '/options',
            storeId: 'quickstart-cd2c5473.myshopify.com',
            chatUrl: shopDomain + '/chat',
            productHeader: 'Products',
            helpfulLabel: 'Helpful?',
            productPlaceholder: 'Skis',
            feedbackPopupChatLength: 500,
            isMainPage: false,
            affiliateLink: '',
            recentProducts: [
                'Ski',
                'Gift Card',
                'Textbook'
            ],
            productButtonLabel: 'Set Product',
            productInstruction: 'Describe what you want to buy to begin:',
            productCardHeader: '',
            showMoreProductsText: 'More Items',
            website: 'https://www.oxford.co.za/',
            externalLinkLabel: 'See on Oxford University Press',
            subheader: 'An AI expert to help you decide what to buy',
            linkSiteSubHeader: false,
            language: 'English',
            botName: 'Shoppy'

        };

