import { PageType } from "./pageType";
import * as Constants from './constants';

const amazonAffiliateLink = process.env.NEXT_PUBLIC_AMAZON_AFFILIATE_LINK;
  const shopDomain = process.env.NEXT_PUBLIC_API_URL;

  export let leahConfig: PageType = {
    id: "LeahHalton",
    logoUrl: '/leah_profile.jpeg',
    logoHeight: 80,
    logoWidth: 80,
    buttonColor: '#3358d4',
    inputPlaceholder: Constants.ConstPlaceHolder,
    helpfulLabel: 'Helpful?',
    shopUrl: shopDomain + '/shopWithX',
    // 'shopUrl': '/api/shop',
    storeId: 'supplements',
    chatUrl: shopDomain + '/chat',
    //'chatUrl': 'api/chat',
    // 'recsUrl':  '/api/recs',
    // 'optionsUrl':  '/api/options',
    recsUrl: shopDomain + '/recPerson',
    optionsUrl: shopDomain + '/options',
    affiliateLink: Constants.affiliateLink,
    productHeader: 'Products',
    productPlaceholder: 'Best makeup for sensitive skin',
    recentProducts: [
      "best foundations for oily skin",
      "top lipsticks for long wear",
      "leading mascaras for volume",
      "best eyeshadow palettes for natural look",
      "top skincare products for acne",
      "leading hair straighteners",
      "best moisturizers for dry skin",
      "top hair dyes for home use",
    ]
    ,
    productButtonLabel: 'Set Product',
    feedbackPopupChatLength: 5,
    productCardHeader: "",
    productInstruction: 'What do you need help buying?',
    subheader: 'An AI expert to help you decide what to buy',
    showMoreProductsText: 'More Items',
    externalLinkLabel: 'See on Amazon',
    website: '/landingpage',
    linkSiteSubHeader: false,
    isMainPage: true,
    language: 'English',
    botName: ''
    //'productInstruction': 'Please select a product from the list below or type your own answer in the input field.',
};




