import { useContext, useState, useRef, useEffect } from 'react';
import { IoEllipsisHorizontal } from "react-icons/io5";
import { FaLink, FaTrash, FaArchive } from 'react-icons/fa';
import { DropdownMenu, DropdownMenuTrigger, DropdownMenuContent } from "../@/components/ui/dropdown-menu";
import { PageContext } from '../contexts/pageContext';

const languageMap = {
    'English': { sharebutton: 'Share', copied: 'Copied!', copyLink: 'Copy link' },
    'en-IN': { sharebutton: 'Share', copied: 'Copied!', copyLink: 'Copy link' },
    'Spanish': { sharebutton: 'Compartir', copied: 'Copiado!', copyLink: 'Copiar link' },
};

export function EllipsesButton({ sessionId, handleArchiveDelete }) {
    const context = useContext(PageContext);
    const [copied, setCopied] = useState(false);
    const [toggleDropdown, setToggleDropdown] = useState(false);
    const link = `https://claros.so/threads/${sessionId}`;


    const handleCopy = async () => {

        await navigator.clipboard.writeText(link);
        setCopied(true);
        setTimeout(() => setCopied(false), 2000);
    };
    const dropdownRef = useRef(null);

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
                setToggleDropdown(false);
            }
        }

        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, []);

    return (
        <div className="absolute bottom-0 top-0 right-0 flex items-center gap-1.5">
            <DropdownMenu>
                <DropdownMenuTrigger asChild>
                    <button className="flex items-center justify-center text-gray-500 transition hover:text-gray-600" type="button" aria-haspopup="menu">
                        <IoEllipsisHorizontal size={24} />
                    </button>
                </DropdownMenuTrigger>
                <DropdownMenuContent className="z-[9999] ml-40 mt-1 rounded-xl shadow-sm border bg-white p-2">
                    <div onClick={(e) => {handleCopy();}} className="block px-4 py-2 hover:bg-gray-100 rounded-xl" style={{ color: copied ? context.buttonColor : 'inherit' }}>
                        <div className="flex items-center text-sm text-gray-700">
                            <FaLink style={{ color: context.buttonColor }} className="mr-2" />

                            {copied ? languageMap[context.language].copied : languageMap[context.language].copyLink}
                        </div>
                    </div>
                    <div onClick={() => {handleArchiveDelete(sessionId)}}className="block px-4 py-2 hover:bg-gray-100 rounded-xl" style={{ color: copied ? context.buttonColor : 'inherit' }}>
                        <div className="flex items-center text-sm text-gray-700">
                        <FaArchive className="mr-2" style={{ color: context.buttonColor }} />
                        Archive
                        </div>
                    </div>
                    <div onClick={() => {handleArchiveDelete(sessionId)}} className="block px-4 py-2 hover:bg-gray-100 rounded-xl" style={{ color: copied ? context.buttonColor : 'inherit' }}>
                        <div className="flex items-center text-sm text-gray-700">
                        <FaTrash className="mr-2" style={{ color: context.buttonColor }} />
                        Delete
                        </div>
                    </div>
                </DropdownMenuContent>
            </DropdownMenu>
        </div>
    );
}

