import {PageType} from './pageType';
import * as Constants from './constants';

const shopDomain = process.env.NEXT_PUBLIC_API_URL;

export let giftemConfig: PageType = {
            id: "giftem",
            logoUrl: '/giftem-logo.svg',
            logoHeight: 80,
            logoWidth: 80,
            //color: '#ff344c',
            buttonColor: '#54335d',
            inputPlaceholder: Constants.ConstPlaceHolder,
            shopUrl: shopDomain + '/shop',
            recsUrl: shopDomain + '/recs',
            optionsUrl: shopDomain + '/options',
            storeId: 'giftem',
            chatUrl: shopDomain + '/chat',
            productHeader: 'Products',
            feedbackPopupChatLength: 500,
            isMainPage: false,
            affiliateLink: Constants.affiliateLink,
            productPlaceholder: 'ProductBot',
            recentProducts: [
                'Candles',
                'Chocolate',
                'Flowers'
            ],
            productButtonLabel: 'Set Gift',
            helpfulLabel: 'Helpful?',
            productInstruction: 'Set Gift Category:',
            productCardHeader: '',
            showMoreProductsText: 'More Items',
            subheader: '',
            website: 'https://giftem.shop/',
            linkSiteSubHeader: false,
            externalLinkLabel: 'See Item',
            language: 'English',
            botName: 'Gifter McGuffins'
        };