
import {PageType} from './pageType';
import * as Constants from './constants';

const shopDomain = process.env.NEXT_PUBLIC_API_URL;

export let momConfig: PageType ={
            id: "mom",
            logoUrl: '/logo.png',
            logoHeight: 80,
            logoWidth: 80,
            //color: '#ff344c',
            buttonColor: '#03adfd',
            inputPlaceholder: "",
            shopUrl: shopDomain + '/shopEverythingTest',
            recsUrl: shopDomain + '/recTest',
            optionsUrl: shopDomain + '/options',
            storeId: 'products_local',
            chatUrl: shopDomain + '/chat',
            productHeader: 'Products',
            feedbackPopupChatLength: 5,
            isMainPage: true,
            affiliateLink: Constants.affiliateLink,
            productPlaceholder: 'bracelet',
            recentProducts: [
                'Candles',
                'Chocolate',
                'Flowers'
            ],
            productButtonLabel: 'Set Gift',
            helpfulLabel: 'Helpful?',
            productInstruction: 'Set Gift Category:',
            productCardHeader: '',
            showMoreProductsText: 'More Items',
            subheader: 'An AI to help you find perfect Mother\'s day gifts',
            website: 'https://getproduct.help',
            'linkSiteSubHeader': false,
            externalLinkLabel: 'See on Amazon',
            language: 'English',
            botName: 'Mombot',
            searchPromptModifier: "This customer is trying to buy a gift for their mom for mother's day. Help customers find thoughtful gifts for their recipients and personalize the gift-finding process to find the most fitting gifts based on what you're told.",
            searchReminder: "Remember, this customer is trying to buy a gift for theri mom for mother's day. Help customers find thoughtful and unqiue gifts for their recipients and personalize the gift-finding process to find the most fitting gifts based on what you're told."
        };