import {PageType} from './pageType';
import * as Constants from './constants';

const shopDomain = process.env.NEXT_PUBLIC_API_URL;

export let producthuntConfig: PageType =
        {
            id: "producthunt",
            logoUrl: '/logo.png',
            logoHeight: 80,
            logoWidth: 80,
            //color: '#ff344c',
            buttonColor: '#ff344c',
            inputPlaceholder: Constants.ConstPlaceHolder,
            shopUrl: shopDomain + '/shopAmazon',
            storeId: 'producthunt',
            chatUrl: shopDomain + '/chat',
            recsUrl: shopDomain + '/recs',
            optionsUrl: shopDomain + '/options',
            productPlaceholder: 'guitar',
            helpfulLabel: 'Helpful?',
            productHeader: 'Products',
            feedbackPopupChatLength: 500,
            isMainPage: false,
            affiliateLink: '',
            recentProducts: [
                'AI chatbot',
                'SaaS for taxes',
                'Email Generator'
            ],
            productButtonLabel: 'Set Product',
            productInstruction: 'Describe what you want to buy to begin:',
            productCardHeader: '', //product recommendatons in spanish
            showMoreProductsText: 'More products',
            website: "https://producthunt.com",
            subheader: 'Producthunt recommendations',
            linkSiteSubHeader: false,
            externalLinkLabel: 'See on Producthunt',
            language: 'English',
            botName: 'Kraven The Hunter'
        };
