import { PageType } from "./pageType";
import * as Constants from './constants';

const amazonAffiliateLink = process.env.NEXT_PUBLIC_AMAZON_AFFILIATE_LINK;
  const shopDomain = process.env.NEXT_PUBLIC_API_URL;

  export let mainConfig: PageType = {
    id: "amazon",
    logoUrl: '/default-black.png',
    logoHeight: 80,
    logoWidth: 80,
    buttonColor: '#0077b6',
    inputPlaceholder: Constants.ConstPlaceHolder,
    helpfulLabel: 'Helpful?',
    shopUrl: shopDomain + '/shopEverythingTest',
    // 'shopUrl': '/api/shop',
    storeId: 'everything',
    chatUrl: shopDomain + '/chat',
    //'chatUrl': 'api/chat',
    // 'recsUrl':  '/api/recs',
    // 'optionsUrl':  '/api/options',
    recsUrl: shopDomain + '/recTest',
    optionsUrl: shopDomain + '/options',
    affiliateLink: Constants.affiliateLink,
    productHeader: 'Products',
    productPlaceholder: 'cheap but cool earbuds',
    recentProducts: [
       'snowboards',
       'guitars',
       'laptops'
    ]
    ,
    productButtonLabel: 'Set Product',
    feedbackPopupChatLength: 5,
    productCardHeader: "",
    productInstruction: 'What book do you want to buy?',
    subheader: 'An AI expert to help you decide what to buy',
    showMoreProductsText: 'More Items',
    externalLinkLabel: 'See on Amazon',
    website: '/landingpage',
    linkSiteSubHeader: false,
    isMainPage: true,
    language: 'English',
    botName: ''
    //'productInstruction': 'Please select a product from the list below or type your own answer in the input field.',
};




