import { PageType } from "./pageType";
import * as Constants from './constants';

const amazonAffiliateLink = process.env.NEXT_PUBLIC_AMAZON_AFFILIATE_LINK;
  const shopDomain = process.env.NEXT_PUBLIC_API_URL;

export let BookConfig: PageType = {
            id: "books",
            logoUrl: '/logo-circle.png',
            logoHeight: 80,
            logoWidth: 80,
            buttonColor: '#0077b6',
            inputPlaceholder: Constants.ConstPlaceHolder,
            shopUrl: shopDomain + '/shopEverythingTest',
            storeId: 'books_oxy',
            chatUrl: shopDomain + '/chat',
            recsUrl: shopDomain + '/recTest',
            optionsUrl: shopDomain + '/options',
            productPlaceholder: 'a comprehensive guide on starting a tech startup in Silicon Valley',
            helpfulLabel: 'Helpful?',
            productHeader: 'Products',
            feedbackPopupChatLength: 500,
            isMainPage: true,
            affiliateLink: Constants.affiliateLink,
            recentProducts: [
              "a comprehensive guide on starting a tech startup in Silicon Valley",
              "a romance novel featuring a friends-to-lovers trope set in a small coastal town",
              "a heartwarming story about a rescue dog finding its forever home",
              "an in-depth guide on sourdough techniques for artisanal bread baking",
              "a dystopian novel about a society controlled by omnipresent surveillance technology",
              "a sci-fi novel featuring a strong female lead navigating a post-apocalyptic world",
              "a suspenseful novel unraveling a deep family secret in a small Midwestern town",
              "a historical fiction novel set during the American Civil War, focusing on the life of a nurse on the battlefield",
              "a mystery novel featuring a female detective solving crimes in Victorian England with the help of her loyal sidekick",
              "a high fantasy novel with a unique magic system based on the harmony of music",
              "a hard science fiction novel exploring the ethical implications of advanced artificial intelligence",
              "a young adult novel featuring a protagonist with the ability to manipulate time"
            ],
            productButtonLabel: 'Set Product',
            productInstruction: 'Got Books? 📚',
            productCardHeader: '', //product recommendatons in spanish
            showMoreProductsText: 'More Books',
            website: "",
            subheader: 'Book recommendations',
            linkSiteSubHeader: false,
            externalLinkLabel: 'See on Amazon',
            language: 'English',
            botName: 'Page Pursuer'
};
