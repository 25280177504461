import { PageType } from "./pageType";
import * as Constants from './constants';

const amazonAffiliateLink = process.env.NEXT_PUBLIC_AMAZON_AFFILIATE_LINK;
  const shopDomain = process.env.NEXT_PUBLIC_API_URL;

export let youtubeConfig: PageType = {
            id: "",
            logoUrl: '/tech.jpeg',
            logoHeight: 80,
            logoWidth: 80,
            buttonColor: '#0077b6',
            inputPlaceholder: Constants.ConstPlaceHolder,
            shopUrl: shopDomain + '/shopWithX',
            storeId: '',
            chatUrl: shopDomain + '/chat',
            recsUrl: shopDomain + '/recPerson',
            optionsUrl: shopDomain + '/options',
            productPlaceholder: '',
            helpfulLabel: 'Helpful?',
            productHeader: 'Products',
            feedbackPopupChatLength: 500,
            isMainPage: true,
            affiliateLink: Constants.affiliateLink,
            recentProducts: [
            ],
            productButtonLabel: 'Set Product',
            productInstruction: 'What are you looking for?',
            productCardHeader: '', //product recommendatons in spanish
            showMoreProductsText: '',
            website: "",
            subheader: '',
            linkSiteSubHeader: false,
            externalLinkLabel: 'See on Amazon',
            language: 'English',
            botName: 'Page Pursuer',
            sources:  []
};
