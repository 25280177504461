
import {PageType} from './pageType';
import * as Constants from './constants';


const shopDomain = process.env.NEXT_PUBLIC_API_URL;

export let oxfordConfig: PageType =
        {

            id: "oxford-co-za",
            logoUrl: '/oxford-logo.png',
            logoHeight: 40,
            logoWidth: 300,
            buttonColor: '#0062b1',
            inputPlaceholder: Constants.ConstPlaceHolder,
            shopUrl: shopDomain + '/shop',
            recsUrl: shopDomain + '/recs',
            optionsUrl: shopDomain + '/options',
            storeId: 'oxford-co-za',
            chatUrl: shopDomain + '/chat',
            productHeader: 'Products',
            helpfulLabel: 'Helpful?',
            productPlaceholder: 'Geometry Textbooks',
            feedbackPopupChatLength: 500,
            isMainPage: false,
            affiliateLink: '',
            recentProducts: [
                'Business Books',
                'United States History',
                'High school biology textbook'
            ],
            productButtonLabel: 'Set Product',
            productInstruction: 'Find me a:',
            productCardHeader: '',
            showMoreProductsText: 'More Items',
            website: 'https://www.oxford.co.za/',
            externalLinkLabel: 'See on Oxford University Press',
            subheader: 'An AI expert to help you decide what to buy',
            linkSiteSubHeader: false,
            language: 'English',
            botName: 'Booky'
        };
