import { PageType } from "./pageType";
import * as Constants from './constants';

const amazonAffiliateLink = process.env.NEXT_PUBLIC_AMAZON_AFFILIATE_LINK;
  const shopDomain = process.env.NEXT_PUBLIC_API_URL;

export let OutdoorConfig: PageType = {
            id: "outdoor",
            logoUrl: '/logo-circle.png',
            logoHeight: 80,
            logoWidth: 80,
            buttonColor: '#0077b6',
            inputPlaceholder: Constants.ConstPlaceHolder,
            shopUrl: shopDomain + '/shopEverythingTest',
            storeId: 'books_oxy',
            chatUrl: shopDomain + '/chat',
            recsUrl: shopDomain + '/recTest',
            optionsUrl: shopDomain + '/options',
            productPlaceholder: 'a lightweight tent for backpacking in high-altitude environments',
            helpfulLabel: 'Helpful?',
            productHeader: 'Products',
            feedbackPopupChatLength: 500,
            isMainPage: true,
            affiliateLink: Constants.affiliateLink,
            recentProducts: [
              "a lightweight tent for backpacking in high-altitude environments",
              "a durable hiking boot for rocky terrains in the Andes",
              "a waterproof jacket for rainy weather hiking in the Pacific Northwest",
              "a portable water purifier for camping in areas with untreated water sources",
              "a thermal sleeping bag for cold weather camping in the Arctic",
              "a high-calorie protein bar for long hikes in energy-demanding trails",
              "a solar-powered portable charger for multi-day camping in areas with abundant sunlight",
              "a multi-tool kit for survival in the wilderness of the Amazon rainforest",
              "a compact first aid kit for outdoor emergencies in remote locations",
              "a UV protection sunglasses for desert hiking in the Sahara",
              "a breathable backpack for long distance trekking in humid climates"
            ],
            productButtonLabel: 'Set Product',
            productInstruction: 'What gear are you looking for?',
            productCardHeader: '', //product recommendatons in spanish
            showMoreProductsText: 'More Books',
            website: "",
            subheader: 'Book recommendations',
            linkSiteSubHeader: false,
            externalLinkLabel: 'See on Amazon',
            language: 'English',
            botName: 'Page Pursuer'
};
